import React, { useEffect } from "react";
import Layout from "../components/_App/layout";
import SEO from "../components/_App/seo";
import Navbar from "../components/_App/NavbarStyleFour";
import Footer from "../components/_App/Footer";

const Prenota = () => {
    useEffect(() => {
        if (document !== null) {
            const script = document.createElement("script");
            script.src =
                "https://assets.calendly.com/assets/external/widget.js";
            script.async = true;
            document.body.appendChild(script);
            return () => {
                document.body.removeChild(script);
            };
        }
    }, []);

    return (
        <Layout>
            <SEO title="Calendario | APPIUS - Software House & Web Agency" />
            <Navbar />
            <div className="bg-calendly">
                <div
                    className="container"
                    style={{ marginTop: 150, textAlign: "start" }}
                >
                    <h2
                        style={{
                            color: "rgb(49, 48, 206)",
                            fontWeight: "bold",
                            fontSize: 32,
                        }}
                        className="text-uppercase Varela"
                    >
                        Prenota una call
                    </h2>
                </div>
                <div
                    className="calendly-inline-widget"
                    data-url="https://calendly.com/appius/30min"
                    style={{ minwidth: 320, height: 670 }}
                ></div>
            </div>
            <Footer />
        </Layout>
    );
};

export default Prenota;
